import { Component } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

declare var gtag: Function;
@Component({
  selector: 'app-sticky-quoate-animation',
  templateUrl: './sticky-quoate-animation.component.html',
  styleUrls: ['./sticky-quoate-animation.component.css']
})
export class StickyQuoateAnimationComponent implements AfterViewInit {
  isModalOpen = false;

  // @ViewChild('content', { static: true }) contentRef!: ElementRef;
   constructor(){}

  // ngAfterViewInit(): void {
  //   const boxes = document.querySelectorAll<HTMLElement>('.box1, .box2');
  //   boxes.forEach((box) => {
  //     ScrollTrigger.create({
  //       trigger: box,
  //       start: 'top top',
  //       end: '+=300',
  //       onEnter: () => {
  //         box.style.transform = 'translateX(0)';
  //         console.log(`${box.className} is now visible`);
  //       },
  //       onLeaveBack: () => {
  //         box.style.transform = 'translateX(100%)';
  //         console.log(`${box.className} is hidden`);
  //       },
  //     });
  //   });
  // }

  // ngAfterViewInit(): void {
  //   const boxes = document.querySelectorAll<HTMLElement>('.box1, .box2');
  //   boxes.forEach((box) => {
  //     ScrollTrigger.create({
  //       trigger: box,
  //       start: 'top top',  
  //       end: '+=300',    
  //       onEnter: () => {
  //         box.style.transform = 'translateX(0)';
  //         console.log(`${box.className} is now visible`);
  //       },
  //       onLeaveBack: () => {
  //         box.style.transform = 'translateX(100%)';
  //         console.log(`${box.className} is hidden`);
  //       },
  //     });
  //     const hideOnBottom = () => {
  //       if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1000) {
  //         console.log(document.documentElement.scrollHeight - 1000, "dddddddddddddddddddddddd")
  //         box.style.transform = 'translateX(100%)';  
  //         console.log(`${box.className} is hidden at the bottom of the screen`);
  //       } else {
  //         box.style.transform = 'translateX(0)';  
  //       }
  //     };
  
  //     window.addEventListener('scroll', hideOnBottom);
  //   });
  // }
  ngAfterViewInit(): void {
  }
  
  // ngAfterViewInit(): void {
  //   const boxes = document.querySelectorAll<HTMLElement>('.box1, .box2');
    
  //   boxes.forEach((box) => {
  //     ScrollTrigger.create({
  //       trigger: box,
  //       start: 'top top',  
  //       end: '+=300',
  //       onEnter: () => {
  //         box.style.transform = 'translateX(0)';
  //       },
  //       onLeave: () => {
  //         box.style.transform = 'translateX(110%)';
  //       },
  //     });

  //     const hideOnBottom = () => {
  //       const isSmallScreen = window.innerWidth <= 768; 
      
  //       if (
  //         ((window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - (isSmallScreen ? 1980 : 850))) || 
  //         window.scrollY < 300
  //       ) {
  //         box.style.transform = 'translateX(110%)';
  //       } else {
  //         box.style.transform = 'translateX(0)';
  //       }
  //     };
  //     const hideOnBottom = () => {
  //       const isSmallScreen = window.innerWidth <= 768;
  //       const topOffset = isSmallScreen ? 1000 : 300;
  //       const bottomOffset = isSmallScreen ? 1980 : 850;
  //       console.log(`ScrollY: ${window.scrollY}`);
  //       console.log(`Window innerHeight: ${window.innerHeight}`);
  //       console.log(`Document scrollHeight: ${document.documentElement.scrollHeight}`);
  //       console.log(`Top Offset: ${topOffset}`);
  //       console.log(`Bottom Offset: ${bottomOffset}`);
  //       if (
  //         window.scrollY < topOffset ||
  //         (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - bottomOffset)
  //       ) {
  //         console.log('Hiding the box');
  //         box.style.transform = 'translateX(110%)';
  //       } else {
  //         console.log('Showing the box');
  //         box.style.transform = 'translateX(0)';
  //       }
  //     };
  //     window.addEventListener('scroll', hideOnBottom);
  //   });
  // }
  
 
  
  
  

    openModal() {
      this.isModalOpen = true;
    }

    closeModal() {
      this.isModalOpen = false;
    }

    gtagReportConversion(url?: string) {
		var callback = function () {
			if (url) {
				window.location.href = url;
			}
		};
		gtag('event', 'conversion', {
			'send_to': 'AW-16722082181/bG7HCOXxjdoZEIXz2qU-',
			'event_callback': callback
		});
		return false;
	}
}
