<app-servicebanner
  heading="Dubai's Top Choice for Dynamic Web Design Services"
  paragraph="Craft stunning, responsive websites with ProWeb. Our expert designs enhance user experience and drive results. Let's bring your vision to life!"
  bannerimg="assets/img/servicesdev/webdesignbg.webp"
  mobileImg="webDesign.webp"
  [cards]="logo"
  [cards2]="logo2"
  [tagline]="taglinearr"
></app-servicebanner>

<!-- breadcrumbs -->
<nav aria-label="breadcrumb" class="ps-5 pt-3 pt-md-0 d-none d-md-block">
  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a href="/">Home</a></li>
    <li class="breadcrumb-item"><a href="/">Services</a></li>
    <li class="breadcrumb-item active" aria-current="page">Web Design</li>
  </ol>
</nav>

<app-technology-stack
  firsttitle="Expert Web Design"
  semititle="Services by Pro Web"
  description="Pro Web is a leading web design company in the UAE with a dedicated team of skilled professionals who excel in a wide range of services. We prioritize creating websites that are not only innovative but also highly effective in driving conversions. Our expertise lies in optimizing websites to deliver the best possible user experience for your customers. Recognizing that a website serves as a crucial touch point for potential leads, we understand that the user's experience while navigating your site can make or break their decision. Our main objective is to craft exceptional product designs that combine creative ideas with cutting-edge technology to effectively showcase your brand's value and facilitate meaningful conversations."
  [cards]="cardsItems"
></app-technology-stack>

<!-- app-portfolio section -->
<app-portfolio
  firsttitle="Web Design"
  semititle="Portfolio"
  desc="Explore our project portfolio - a showcase of innovation and excellence"
  [portfolio]="portfolioItems"
></app-portfolio>
<!-- app-portfolio section -->
 
<!-- the proweb way section -->
<section class="spacing">
  <div class="proweb-padding">
    <div class="row justify-content-center processWrap">
      <h2 class="my-3">The <span>Pro Web Way</span></h2>

      <div class="d-none d-md-flex p-3">
        <div class="row px-2">
          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/process-way/requirement-gray.png"
                    class="gray"
                    alt=""
                    srcset=""
                  />
                  <img
                    src="../../assets/img/servicesdev/process-way/requiremnet-orange.png"
                    class="orange"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/straight-arrow2.svg"
                    class="top-arrow"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">Requirement Gathering</h5>
                <ul>
                  <li>
                    Understanding client requirements, goals, and target
                    audience
                  </li>
                  <li>
                    Gathering information on branding guidelines, content, and
                    desired features
                  </li>
                  <li>
                    Determining the technologies and tools required to execute
                    the project effectively
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/process-way/uiux-gray.png"
                    class="gray"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/process-way/uiux-orange.png"
                    class="orange"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/straight-arrow2.svg"
                    class="top-arrow"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">UI/UX Design</h5>
                <ul>
                  <li>
                    Developing wireframes and prototypes to visualize website
                    structure and user flow
                  </li>
                  <li>
                    Creating high-fidelity mockups incorporating branding
                    elements and intuitive navigation
                  </li>
                  <li>
                    Ensuring consistency, accessibility, and usability
                    throughout the design
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/process-way/webdesign-gray.png"
                    class="gray"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/process-way/webdesign-orange.png"
                    class="orange"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/straight-arrow2.svg"
                    class="top-arrow"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">Web Designing</h5>
                <ul>
                  <li>
                    Translating approved design concepts into HTML, CSS, and
                    JavaScript code
                  </li>
                  <li>
                    Implementing responsive design techniques to optimize for
                    various devices and screen sizes
                  </li>
                  <li>
                    Integrating multimedia elements, animations, and interactive
                    features as per design specifications
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/process-way/testingdesign-gray.png"
                    class="gray"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/process-way/testingdesign-orange.png"
                    class="orange"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/straight-arrow2.svg"
                    class="top-arrow"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">Testing</h5>
                <ul>
                  <li>
                    Conducting comprehensive testing across different browsers,
                    devices, and operating systems
                  </li>
                  <li>
                    Verifying functionality, usability, and performance to
                    identify and resolve issues
                  </li>
                  <li>
                    Performing user testing to gather feedback and validate the
                    user experience
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="col">
            <div class="cardwrap">
              <div class="text-start p-3">
                <div class="d-flex">
                  <img
                    src="../../assets/img/servicesdev/process-way/launch-gray.png"
                    class="gray"
                    alt=""
                    srcset=""
                  />

                  <img
                    src="../../assets/img/servicesdev/process-way/launch-orange.png"
                    class="orange"
                    alt=""
                    srcset=""
                  />
                </div>
              </div>
              <div class="cardcontent">
                <h5 class="text-start fw-bold">Launch</h5>
                <ul>
                  <li>
                    Deploying the website to the live server and configuring
                    domain settings
                  </li>
                  <li>
                    Monitoring server performance and addressing any issues
                    during deployment
                  </li>
                  <li>
                    Conducting final checks to ensure website functionality and
                    accessibility
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-block d-md-none my-4">
        <ngx-slick-carousel
          class="carousel owl-carousel branflex processSlider"
          [config]="carouselConfig"
        >
          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/process-way/requiremnet-orange.png"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>
              <h5 class="text-center fw-bold my-3">Requirement Gathering</h5>
              <ul>
                <li>
                  Understanding client requirements, goals, and target audience
                </li>
                <li>
                  Gathering information on branding guidelines, content, and
                  desired features
                </li>
                <li>
                  Determining the technologies and tools required to execute the
                  project effectively
                </li>
              </ul>
            </div>
          </div>

          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/process-way/uiux-orange.png"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>
              <h5 class="text-center fw-bold my-3">UI/UX Design</h5>
              <ul>
                <li>
                  Developing wireframes and prototypes to visualize website
                  structure and user flow
                </li>
                <li>
                  Creating high-fidelity mockups incorporating branding elements
                  and intuitive navigation
                </li>
                <li>
                  Ensuring consistency, accessibility, and usability throughout
                  the design
                </li>
              </ul>
            </div>
          </div>

          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/process-way/analysis-orange.png"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>
              <h5 class="text-center fw-bold my-3">Web Designing</h5>
              <ul>
                <li>
                  Translating approved design concepts into HTML, CSS, and
                  JavaScript code.
                </li>
                <li>
                  Implementing responsive design techniques to optimize for
                  various devices and screen sizes
                </li>
                <li>
                  Integrating multimedia elements, animations, and interactive
                  features as per design specifications
                </li>
              </ul>
            </div>
          </div>

          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/process-way/testingdesign-orange.png"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>
              <h5 class="text-center fw-bold mb-2">Testing</h5>
              <ul>
                <li>
                  Conducting comprehensive testing across different browsers,
                  devices, and operating systems
                </li>
                <li>
                  Verifying functionality, usability, and performance to
                  identify and resolve issues
                </li>
                <li>
                  Performing user testing to gather feedback and validate the
                  user experience
                </li>
              </ul>
            </div>
          </div>

          <div class="cardwrap" ngxSlickItem class="slider-inlinefixe">
            <div class="cardcontent">
              <div class="text-center p-3">
                <img
                  src="/assets/img/servicesdev/process-way/launch-orange.png"
                  class="orange"
                  alt=""
                  srcset=""
                />
              </div>
              <h5 class="text-center fw-bold my-3">Launch</h5>
              <ul>
                <li>
                  Deploying the website to the live server and configuring
                  domain settings
                </li>
                <li>
                  Monitoring server performance and addressing any issues during
                  deployment
                </li>
                <li>
                  Conducting final checks to ensure website functionality and
                  accessibility
                </li>
              </ul>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
</section>

<app-engagement-modal></app-engagement-modal>


<!-- <app-portfolio
  firsttitle="Web Design"
  semititle="Portfolio"
  desc="Explore our project portfolio - a showcase of innovation and excellence"
  [portfolio]="portfolioItems"
></app-portfolio> -->

<app-customized-solutions [htmlStr]="designStr"></app-customized-solutions>

<app-testimonial-service
  profile="aditya-profile.webp"
  clientname="Aditya Jagtap"
  designation="Founder - The Bezel Club"
  location="United
Arab Emirates"
  flag="uk-flag.svg"
  clientcomment="Had a great experience working with Rohit Sadani and his team. They were very responsive and did a great job with our company website. They were also open to quick changes and available when required."
  rating="Rated 4.9 out of 5.0"
  [clientimg]="data"
  [clientmobile]="datamobile"
  clienttarget="The_travel_hub_logo.svg"
></app-testimonial-service>

<app-why-choose-proweb
  heading="Why Choose Pro Web For Website Design?"
  desc="We excel in website design, delivering top-tier solutions with expertise and reliability."
  [obj]="cards"
></app-why-choose-proweb>

<app-check-out
  checkoutImg="../../assets/img/servicesdev/checkout_mainimg.png"
  alt="check out"
  checkoutText="Check Out Our"
  checkoutName="Web Maintenance Packages"
  checkoutUrl="/website-maintenance-packages"
></app-check-out>

<app-bottom-bar
  text="Let’s Work Together to Build Your Dream Website!"
></app-bottom-bar>

<section class="spacing">
  <div class="container-md">
    <div class="row justify-content-center">
      <h2>Frequently <span>Asked Questions</span></h2>
      <p class="my-3">
        Our industry expertise and research capabilities drive our leadership in
        developing advanced engagement models.
      </p>

      <div class="row my-4">
        <div class="col-sm-6">
          <div
            class="faq-list"
            *ngFor="let item of faq.slice(0, 4); let i = index"
          >
            <div
              class="faq-title d-flex justify-content-between"
              (click)="toggleAccordian($event, i)"
            >
              <h6>{{ item.title }}</h6>
              <!-- <span class="arrow" [ngClass]="{'up': item.isActive}"></span>-->

              <h4 *ngIf="item.isActive == false">+</h4>
              <h4 *ngIf="item.isActive == true">-</h4>
            </div>
            <div class="faq-des" *ngIf="item.isActive">
              <div [innerHTML]="item.des"></div>
            </div>
          </div>
        </div>

        <div class="col-sm-6">
          <div
            class="faq-list"
            *ngFor="let item of faq.slice(4); let i = index"
          >
            <div
              class="faq-title d-flex justify-content-between"
              (click)="toggleAccordian($event, i + 4)"
            >
              <h6>{{ item.title }}</h6>
              <!-- <span class="arrow" [ngClass]="{'up': item.isActive}"></span> -->

              <h4 *ngIf="item.isActive == false">+</h4>
              <h4 *ngIf="item.isActive == true">-</h4>
            </div>
            <div class="faq-des" *ngIf="item.isActive">
              <div [innerHTML]="item.des"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
