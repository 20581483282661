<div #viewport class="viewport">
    <div #content class="content">
		<div class="box">
			<div class="box1">
				<p class="textpBox1" (click)="openModal()"> Get A Quote</p>
			</div>
			<div class="box2">
				<div class="box2child">
					<a (click)="gtagReportConversion('https://api.whatsapp.com/send/?phone=971527732125&text&type=phone_number&app_absent=0')" target="_blank" aria-label="whatapp" href="https://api.whatsapp.com/send/?phone=971527732125&text&type=phone_number&app_absent=0">
						<img src="assets/img/newWhatsaapQuotegreenouter.svg" alt="whatsapp" srcset="" class="newWhatsappClass">
					</a>
					<div class="wa__btn_popup_txt">
						<span class="NeedHelp">Need Help?</span>
						<strong> Chat with us </strong>
					</div>
				</div>
			</div>
		</div>
    </div>
</div>
<app-quoate-model  *ngIf="isModalOpen" (closeModal)="closeModal()"></app-quoate-model> 