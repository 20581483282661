<div class="modal-overlay " (click)="closed()">
   
    <div class="modal-content" (click)="$event.stopPropagation()">
        <div class="boalParent">
        <div class="crossSvgDiv">
            <div class="crossSvg" (click)="close()">
               <img src="assets/img/crossSvgFinalQuote.png" alt="" class="crossSvgsvg"  (click)="close()">
            </div>
        </div>
    </div>
    <div class="quoteTextParent">
        <img src="assets/img/designedQuoteText.png" alt="" class="quoteTextImg">
    </div>
            <div class="form fadeInBottom">
                <div class="formhead">
                    <app-alert></app-alert>
                    <form class="m-0" [formGroup]="quoteForm" (ngSubmit)="onSubmit($event)" #quoteFormNew="ngForm"
                        class="foocon-form mb-2" enctype="multipart/form-data" id="quoteForm">
                        <div class="formLoader" *ngIf="showLoader">Please Wait...</div>
                        <input type="hidden" name="type" formControlName="type" value="QUOTE">
                        <div class="row">
                            <div class="col-md-6 pl-0">
                                <div class="mb-2 newClassmb">
                                    <input type="text" class="inpstyle" placeholder="What’s your name?*" name="name"
                                        formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">

                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Please enter your name</div>
                                    </div>
                                </div>
                                <!-- <div class="d-flex mb-2 newClassmb countrycontact">
                                    <select name="country_code" formControlName="country_code" class="ctry-select">
                                        <option *ngFor="let country of countries"
                                            [attr.data-countryCode]="country.country_code"
                                            value="{{country.phone_code}}">+{{ country.phone_code }}</option>
                                            
                                    </select>
                                    <div class="contactfield">
                                        <input type="number" name="phone_number" formControlName="phone_number"
                                            (keypress)="numberOnly($event)"
                                            [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }"
                                            (countryChange)="countryChange($event)" class="inpstyle contactinp"
                                            placeholder="Contact No.*">
                                        <div *ngIf="submitted && f.phone_number.errors" class="invalid-feedback">
                                            <div *ngIf="f.phone_number.errors.required">Please, enter Phone
                                                number</div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="d-flex mb-2 newClassmb countrycontact">
                                    <!-- Country Code Select -->
                                    <select name="country_code" formControlName="country_code" class="ctry-select">
                                      <option *ngFor="let country of countries" 
                                              [attr.data-countryCode]="country.country_code" 
                                              value="{{country.phone_code}}">
                                        +{{ country.phone_code }}
                                      </option>
                                    </select>
                                    <div class="contactfield">
                                      <input 
                                        type="number" 
                                        name="phone_number" 
                                        formControlName="phone_number"
                                        (keypress)="numberOnly($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }"
                                        (countryChange)="countryChange($event)" 
                                        class="inpstyle contactinp"
                                        placeholder="How we shall contact you ?*"
                                      />
                                    </div>
                                  </div>
                                  
                                  <!-- Error Message Outside -->
                                  <div *ngIf="submitted && f.phone_number?.errors" class="inavlidNum">
                                    <div *ngIf="f.phone_number?.errors?.required">
                                      Please enter Phone number
                                    </div>
                                  </div>
                                  
                                <div class="mb-2 newClassmb">
                                    <input type="email" name="email" formControlName="email"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" class="inpstyle"
                                        placeholder="Your email* ">

                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Please enter a valid email</div>
                                        <div *ngIf="f.email.errors.email">Please enter a valid email</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 pr-0">
                                <div class="mb-2 newClassmb">
                                    <textarea name="message" formControlName="message"
                                        [ngClass]="{ 'is-invalid': submitted && f.message.errors }" rows="5" cols="45"
                                        class="inpstyle" placeholder="What’s your project about?*" ></textarea>

                                    <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                        <div *ngIf="f.message.errors.required">Message is required</div>
                                    </div>
                                </div>
                                    <button class="btn btn-primary d-block w-100 mt-3">Submit</button>
                                    <!-- <button class="btn btn-primary d-block">Submit</button> -->
                            </div>
                        </div>
                           <div class="row rowCheckbox">
                            <div class="col-md-6"></div>
                            <div class="col-md-6">
                                <div class="form-check"> 
                                    <input class="form-check-input custom-checkbox" type="checkbox" name="is_whatsapp" value="1" id="flexCheckDefault">
                                    <label class="form-check-label" for="flexCheckDefault">
                                      Connect On WhatsApp
                                    </label>
                                  </div>
                            </div>
                           </div>  
                    </form>
                </div>
            </div>
      
    </div>
</div>