<footer *ngIf="!hideFooter" class="footer-sec">
    <img src="assets/img/webp/homepage/proweb-globe-icon.webp" alt="pro web" class="proweb-globe" />
    <!-- <img src="assets/img/webp/homepage/skyline-image.webp" alt="skyline" class="skyline-image" /> -->
    <div class="footer-grad-bg">
        <div class="container-md">
            <section class="footer-partner-sec">
                <div class="container">
                    <div class="p-grid">
                        <div class="p-col-12">
                            <h4 class="partner-slidehead">Our Valuable Partners</h4>
                            <section class="team-area ptb-30">
                                <div class="container">
                                    <div class="row m-0">
                                        <ngx-slick-carousel class="carousel owl-carousel  branflex"
                                            [config]="carouselConfig">
                                            <div ngxSlickItem *ngFor="let partner of partners"
                                                class="slider-inlinefixe">
                                                <div class="industries-image">
                                                    <img src="{{partner.Img}}" width="" height="" alt={{partner.Img}}>
                                                </div>
                                            </div>
                                        </ngx-slick-carousel>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <div class="row">
                <div class="col-md-12">
                    <div class="formbot-row">
                        <div class="formbot-left">
                            <div class="row px-0">
                                <div class="col-md-5">
                                    <div class="address-sec-inner">
                                        <h4 class="service_location mb-3"> Pro Web </h4>
                                        <ul class="packageList">
                                            <li>
                                                <span class="servicename"> <a routerLink="/about-us"
                                                        routerLinkActive="active" class="nav-link"> About Us </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/portfolio"
                                                        routerLinkActive="active" class="nav-link"> Our Work </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/case-study"
                                                        routerLinkActive="active" class="nav-link"> Case Studies
                                                    </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/careers"
                                                        routerLinkActive="active" class="nav-link"> Career </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/blog"
                                                        routerLinkActive="active" class="nav-link"> Blog </a></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- dekstop location -->
                                <div class="col-md-7 desktoplocation" *ngIf="isDesktop">
                                    <div class="address-sec-inner">
                                        <h4 class="service_location mb-3"> Location </h4>
                                        <div style="line-height: 30px;">
                                            <img src="assets/img/locationoffice.svg" style="filter: invert(1);"
                                                alt="uae-flag">
                                            <span class="address">
                                                Office #1606, SIT Tower,
                                                DSO,
                                                Dubai, UAE
                                            </span>
                                        </div>
                                        <div style="line-height: 30px;">
                                            <!-- <a href="https://wa.me/971526549225" target="_blank" -->
                                            <!-- <a href="https://wa.me/971527732125" target="_blank"
                                            class="text-white">
                                            <img src="assets/img/whatsupicon.svg" width="20" alt="call">
                                            +971 52 773 2125
                                         
                                        </a> -->
                                        <!-- <br> -->
                                            <a href="tel:+971528050084" class="text-white">
                                                <img src="assets/img/footermobileicon.svg" style="filter: invert(1);"
                                                    alt="call">
                                                +971 5280 50084
                                            </a>
                                           
                                        </div>
                                        <div style="line-height: 30px;">
                                            <a href="mailto:info@prowebtechnos.com" (click)="gtagReportConversion('mailto:info@prowebtechnos.com')" class="text-white">
                                                <img src="assets/img/Emailicon.svg" alt="email" width="20">
                                                info&#64;prowebtechnos.com
                                            </a>
                                        </div>
                                        <!-- <div style="line-height: 30px;">
                                            <a href="mailto:contact@prowebtechnos.com" class="text-white">
                                                <img src="assets/img/Emailicon.svg" alt="email" width="20">
                                                contact&#64;prowebtechnos.com
                                            </a>
                                        </div> -->
                                        <div style="line-height: 30px;">
                                            <a href="https://join.skype.com/invite/JeyeMyPXXn8I" target="_blank" class="text-white">
                                                <img src="assets/img/skype_logo1.svg" alt="skype" width="18">
                                                prowebtechnos
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!-- mobile package -->
                                <div class="col-md-5 mobilepackage" *ngIf="isMobile">
                                    <div class="address-sec-inner">
                                        <h4 class="service_location mb-3"> Packages </h4>
                                        <ul class="packageList">
                                            <li>
                                                <span class="servicename"> <a routerLink="/seo-packages"
                                                        routerLinkActive="active" class="nav-link"> SEO Packages
                                                    </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/ppc-packages"
                                                        routerLinkActive="active" class="nav-link"> PPC Packages
                                                    </a></span>
                                            </li>

                                            <li><span class="servicename"><a routerLink="/smm-packages"
                                                        routerLinkActive="active" class="nav-link"> SMM
                                                        Packages </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/website-maintenance-packages"
                                                        routerLinkActive="active" class="nav-link"> Web Maintenance
                                                        Packages </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/web-hosting-packages"
                                                        routerLinkActive="active" class="nav-link"> Web Hosting Packages
                                                    </a></span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-0 mt-md-4">
                                <!-- desktop package -->
                                <div class="col-md-5 desktoppackage" *ngIf="isDesktop">
                                    <div class="address-sec-inner">
                                        <h4 class="service_location mb-3"> Packages </h4>
                                        <ul class="packageList">
                                            <li>
                                                <span class="servicename"> <a routerLink="/seo-packages"
                                                        routerLinkActive="active" class="nav-link"> SEO Packages
                                                    </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/ppc-packages"
                                                        routerLinkActive="active" class="nav-link"> PPC Packages
                                                    </a></span>
                                            </li>

                                            <li><span class="servicename"><a routerLink="/smm-packages"
                                                        routerLinkActive="active" class="nav-link"> SMM
                                                        Packages </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/website-maintenance-packages"
                                                        routerLinkActive="active" class="nav-link"> Web Maintenance
                                                        Packages </a></span>
                                            </li>
                                            <li>
                                                <span class="servicename"><a routerLink="/web-hosting-packages"
                                                        routerLinkActive="active" class="nav-link"> Web Hosting Packages
                                                    </a></span>
                                            </li>
                                        </ul>
                                    </div>


                                    <h4 class="service_location mt-0 mt-md-4">
                                        Connect With Us
                                    </h4>


                                    <div class="foo-social-bar mt-4">
                                        <div class="">
                                            <ul class="mb-3">
                                                <li class="me-1 fbhover">
                                                    <a href="https://www.facebook.com/proweb.ae/" class="foo-fb" aria-label="facebook"
                                                        target="_blank">
                                                         <img src="assets/img/webp/footer/facebook.webp" alt="" srcset="">
                                                    </a>
                                                </li>
                                                <li class="me-1 instahover">
                                                    <a href="https://www.instagram.com/proweb.ae/" class="foo-insta"
                                                    aria-label="instagram"  target="_blank">
                                                        <img src="assets/img/webp/footer/instagram.webp" alt="" srcset="">
                                                    </a>
                                                </li>
                                                <li class="me-1 linkedin">
                                                    <a href="https://www.linkedin.com/company/prowebdubai/?viewAsMember=true"
                                                    aria-label="linkedin" class="foo-linkedin" target="_blank">
                                                        <img src="assets/img/webp/footer/linkedin.webp" alt="" srcset="">
                                                    </a>
                                                </li>

                                                <li class="me-1 blackhover">
                                                    <a href="https://twitter.com/proweb_uae" target="_blank"  aria-label="twitter">
                                                        <img src="assets/img/webp/footer/new_twitter.webp"
                                                            alt="" srcset="">
                                                    </a>
                                                </li>


                                            </ul>
                                        </div>
                                    </div>


                                </div>

                                <!-- mobile location -->
                                <div class="col-md-7 mobileplocation" *ngIf="isMobile">
                                    <div class="address-sec-inner">
                                        <h4 class="service_location mb-3"> Location </h4>
                                        <div style="line-height: 30px;">
                                            <img src="assets/img/locationoffice.svg" style="filter: invert(1);"
                                                alt="uae-flag">
                                            <span class="address">
                                                Office #1606, SIT Tower,
                                                DSO,
                                                Dubai, UAE
                                            </span>
                                        </div>
                                        <div style="line-height: 30px;">
                                            <a href="tel:+971528050084" class="text-white">
                                                <img src="assets/img/footermobileicon.svg" style="filter: invert(1);"
                                                    alt="call">
                                                +971 5280 50084
                                            </a> 
                                            <br>
                                            <!-- <a href="https://wa.me/971526549225" target="_blank" class="text-white ms-1 md:ms-2">
                                                <img src="assets/img/whatsupicon.svg" width="18" alt="call" >
                                                
                                                +971 5265 49225 , +971 5280 50084
                                            </a> -->
                                        </div>
                                        <div style="line-height: 30px;  margin-left: 3px;">
                                            <a href="mailto:info@prowebtechnos.com" class="text-white">
                                                <img src="assets/img/Emailicon.svg" alt="email" width="20">
                                                info&#64;prowebtechnos.com
                                            </a>
                                        </div>
                                        <!-- <div style="line-height: 30px;  margin-left: 3px;">
                                            <a href="mailto:contact@prowebtechnos.com" class="text-white">
                                                <img src="assets/img/Emailicon.svg" alt="email" width="20">
                                                contact&#64;prowebtechnos.com
                                            </a>
                                        </div> -->
                                        <div style="line-height: 30px;  margin-left: 3px;">
                                            <a href="https://join.skype.com/invite/JeyeMyPXXn8I" target="_blank" class="text-white">
                                                <img src="assets/img/skype_logo1.svg" alt="skype" width="15">
                                                prowebtechnos
                                            </a>
                                        </div>
                                    </div>
                                    <h4 class="service_location mt-4">
                                        Connect With Us
                                    </h4>
                                    <div class="foo-social-bar mt-4">
                                        <div class="">                                                                                                                                  
                                            <ul class="mb-3">
                                                <li>
                                                    <a href="https://www.facebook.com/proweb.ae/" aria-label="fb" class="foo-fb"
                                                        target="_blank">
                                                        <img src="assets/img/webp/footer/facebook.webp" alt="" srcset="">                                                    
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/proweb.ae/" aria-label="insta" class="foo-insta"
                                                        target="_blank">
                                                        <img src="assets/img/webp/footer/instagram.webp" alt="" srcset="">
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.linkedin.com/company/prowebdubai/?viewAsMember=true" aria-label="linkedin"
                                                        class="foo-linkedin" target="_blank">
                                                        <img src="assets/img/webp/footer/linkedin.webp" alt="" srcset="">
                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="https://twitter.com/proweb_uae" aria-label="twitter" target="_blank">
                                                        <img src="assets/img/webp/footer/new_twitter.webp"
                                                            alt="" srcset="">
                                                    </a>
                                                </li>


                                            </ul>
                                        </div>
                                    </div>



                                </div>

                                <div class="col-md-7">
                                    <div class="address-sec-inner">
                                        <h4 class="service_location mb-3"> Find Us On Google Map </h4>
                                        <div>
                                            <iframe class="map-wrapper" title="google_map"
                                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14450.111666476441!2d55.39063!3d25.117837!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f58297ffde587%3A0x557dd64882fcdefd!2sPro%20Web!5e0!3m2!1sen!2sin!4v1699275516159!5m2!1sen!2sin"
                                                width="380" height="260" style="border:0;" allowfullscreen=""
                                                loading="lazy" referrerpolicy="no-referrer-when-downgrade"
                                                style="border-radius: 10px; overflow: hidden;"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-md-flex justify-content-between formwrap main">

                            <div class="form">
                                <div class="formhead">
                                    <div class="mt-2">
                                        <h4 class="form_head">Request Callback</h4>

                                        <p class="subhead mb-2">PLEASE PROVIDE YOUR DETAILS BELOW</p>
                                    </div>
                                    <app-alert></app-alert>
                                    <form [formGroup]="footerForm" (ngSubmit)="onSubmit($event)" #footerFormNew="ngForm"
                                        class="foocon-form mb-4" enctype="multipart/form-data" id="footerForm">
                                        <div class="formLoader" *ngIf="showLoader">Please Wait...</div>
                                        <div class="mb-2">
                                            <input type="text" class="inpstyle" placeholder="Name*" name="name"
                                                formControlName="name"
                                                [ngClass]="{ 'is-invalid': submitted && f.name.errors }">

                                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                                <div *ngIf="f.name.errors.required">Please enter your name</div>
                                            </div>
                                        </div>
                                        <div class="d-flex mb-2 countrycontact">
                                            <select name="country_code" formControlName="country_code"
                                                class="ctry-select">
                                                <option *ngFor="let country of countries"
                                                    [attr.data-countryCode]="country.country_code"
                                                    value="{{country.phone_code}}">+{{ country.phone_code }}</option>
                                            </select>
                                            <div class="contactfield">
                                                <input type="number" name="phone_number" formControlName="phone_number"
                                                    (keypress)="numberOnly($event)"
                                                    [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }"
                                                    (countryChange)="countryChange($event)" class="inpstyle contactinp"
                                                    placeholder="Contact No.*">
                                                <!-- <div *ngIf="submitted && f.phone_number.errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="f.phone_number.errors.required">Please, enter Phone
                                                        number</div>
                                                </div> -->
                                            </div>
                                        </div> 
                                        <div *ngIf="submitted && f.phone_number.errors"class="inavlidNum" >
                                            <div *ngIf="f.phone_number.errors.required">Please enter Phone number</div>
                                        </div>
                                        <!-- <div class="d-flex mb-2 newClassmb countrycontact">
                                          
                                            <select name="country_code" formControlName="country_code" class="ctry-select">
                                              <option *ngFor="let country of countries" 
                                                      [attr.data-countryCode]="country.country_code" 
                                                      value="{{country.phone_code}}">
                                                +{{ country.phone_code }}
                                              </option>
                                            </select>
                                            <div class="contactfield">
                                              <input 
                                                type="number" 
                                                name="phone_number" 
                                                formControlName="phone_number"
                                                (keypress)="numberOnly($event)"
                                                [ngClass]="{ 'is-invalid': submitted && f.phone_number.errors }"
                                                (countryChange)="countryChange($event)" 
                                                class="inpstyle contactinp"
                                                placeholder="How we shall contact you ?*"
                                              />
                                            </div>
                                          </div>
                                        
                                    <div *ngIf="submitted && f.phone_number?.errors" class="inavlidNum">
                                        <div *ngIf="f.phone_number?.errors?.required">
                                        Please, enter Phone number
                                        </div>
                                    </div> -->
                                        <div class="mb-2">
                                            <input type="email" name="email" formControlName="email"
                                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                                class="inpstyle" placeholder="Email*">

                                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                <div *ngIf="f.email.errors.required">Please enter a valid email</div>
                                                <div *ngIf="f.email.errors.email">Please enter a valid email</div>
                                            </div>
                                        </div>
                                        <div>
                                            <textarea name="message" formControlName="message"
                                                [ngClass]="{ 'is-invalid': submitted && f.message.errors }" rows="5"
                                                cols="45" class="inpstyle"
                                                placeholder="I would like to discuss about..."></textarea>

                                            <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                                <div *ngIf="f.message.errors.required">Message is required</div>
                                            </div>
                                        </div>
                                        <button class="btn btn-primary d-block w-100 mt-3">Request Now</button>
                                    </form>


                                    <p class="foo-form-infotxt py-3"><span>We only need your e-mail address in case we
                                            can't reach you so we can still get in touch.</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="made_with mb-3">
                <p>Crafted with <img src="assets/img/webp/footer/heart.webp" class="mx-1" alt="" srcset="">  in UAE and proudly serving</p>
                <ul>
                    <li class="px-2">India</li>
                    <li class="px-2">Singapore </li>
                    <li class="px-2">Canada</li>
                    <li class="px-2">USA</li>
                    <li class="px-2">Europe </li>
                    <li class="px-2">Australia</li>
                </ul>
            </div>
        </div>
        <div class="row footer-copyright">
            <div class="container mb-4 mb-md-0">
                <div class="d-md-flex px-2 justify-content-between">
                    <p class="footer-copyright my-2"> Copyright © 2025 Unisys Technologies L.L.C. All Rights Reserved.
                    </p>
                    <p class="footer-copyright my-2">
                        <a href="/privacy-policy" class="px-2 text-white">Privacy Policy </a>
                        |
                        <a href="/terms-and-conditions" class="px-2 text-white"> Terms & Conditions </a>
                        |
                        <a href="/sitemap" class="px-2 text-white">Sitemap</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="go-top"></div>
