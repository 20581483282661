import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../_alert';
import { LoadingService } from '../../utils/services/loading.service';

declare var gtag: Function;
interface blogData {
	response: any;
}
@Component({
	selector: 'app-blog-details',
	templateUrl: './blog-details.component.html',
	styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {

	title = "";
	metaDescription = "";
	metaKeywords = "";

	blogs: any = [];
	id: string;
	blogCatList: any = [];
	FormDropDownList: any = [];
	formCategories: any = [];
	formServices: any = [];
	formBudgets: any = [];

	public current_route: string = "";
	public pageId: string = "";
	public sharehref: string = "";

	mySubscription: any;

	blogcontactForm: any;
	submitted = false;
	isDisabled = false;
	showLoader = false;

	serverUrl = environment.baseUrl;
	apiurl = environment.apiUrl;
	uploadUrl = environment.uploadUrl;
	blogUrl = environment.blogUrl

	constructor(
		private titleService: Title,
		private metaTagService: Meta,
		private httpClient: HttpClient,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		private alertService: AlertService,
		private loadingService: LoadingService,

	) { this.id = this.route.snapshot.params['id']; }

	ngOnInit() {
		this.loadingService.setLoadingState(true);

		setTimeout(() => {
		  this.loadingService.setLoadingState(false);
		}, 2000)
		
		this.metaTagService.updateTag({ name: 'description', content: '' });
		this.metaTagService.updateTag({ name: 'keywords', content: '' });

		this.get_detail();

		this.current_route = this.router.url;
		this.pageId = this.current_route;

		this.sharehref = window.location.href;

		this.blog_categories();
		this.blog_form_drodown();

		this.blogcontactForm = this.formBuilder.group({
			name: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			country_code: ['91'],
			phone: ['', Validators.required],
			skype: [''],
			businessurl: [''],
			category: [''],
			service: [''],
			budget: [''],
			subject: [''],
			message: ['', Validators.required]
		});
	}

	get_detail() {
		this.httpClient.get<blogData>(this.blogUrl + 'blog_details/' + this.id).subscribe(async data => {
			this.title = data?.response?.meta_title;
			this.metaDescription = data?.response?.meta_description;
			// this.metaKeywords = data?.response?.meta_title;

			this.titleService.setTitle(this.title);
			this.metaTagService.updateTag({ name: 'description', content: this.metaDescription });
			// this.metaTagService.updateTag({ name: 'keywords', content: this.metaKeywords });

			this.blogs.push(data.response);
		});
	}

	getblog(primeid: any) {
		this.httpClient.get<blogData>(this.blogUrl + 'blog_details/' + primeid).subscribe(async data => {
			this.blogs.push(data.response);
			// console.log(data['data'][0]);
			// this.router.navigate(['/blog-detail', primeid]);
			this.router.navigateByUrl('/BlogDetailsComponent', { skipLocationChange: true }).then(() => {
				this.router.navigate(['/blog-detail', primeid]);
			});
		});
	}

	blog_categories() {
		this.httpClient.get(this.blogUrl + 'blog_categories').subscribe(data => {
			let ClientsData: any = data;
			this.blogCatList = ClientsData.response;
		});
	}

	blog_form_drodown() {
		this.httpClient.get(this.apiurl + 'blog_form_dropdown').subscribe(data => {
			let ClientsData: any = data;
			this.FormDropDownList = ClientsData;

			this.formCategories = this.FormDropDownList.categories;
			this.formServices = this.FormDropDownList.services;
			this.formBudgets = this.FormDropDownList.budgets;
		});
	}

	telInputObject(obj: any) {
		obj.setCountry('in');
	}
	countryChange(country: any) {
		this.blogcontactForm.controls.country_code.setValue(country.dialCode);
	}
	numberOnly(event: any): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}

	get f() { return this.blogcontactForm.controls; }

	onSubmit(event: any) {
		this.submitted = true;
		// stop here if form is invalid
		if (this.blogcontactForm.invalid) {
			return;
		}
		this.showLoader = true;
		const formData = new FormData(event.target);
		this.httpClient.post(this.apiurl + 'blog_quick_contact', formData).subscribe(data => {
			this.isDisabled = false;
			this.showLoader = false;
			let ClientsData: any = data;
			if (ClientsData.success == true) {
				gtag('event', 'conversion', {'send_to': 'AW-16722082181/iGVfCPeswNsZEIXz2qU-'});
				
				this.alertService.clear();
				this.alertService.success(ClientsData.message);
				this.submitted = false;
				this.blogcontactForm.reset();
				this.router.navigate(['/thankyou']);
			} else {
				this.alertService.clear();
				this.alertService.error(ClientsData.message);
			}
		});
	}
}