import { Component ,Output, EventEmitter ,ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, NavigationEnd } from "@angular/router";
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AlertService } from '../_alert';
import { Platform } from '@angular/cdk/platform';

@Injectable({
	providedIn: 'root'
})

@Component({
	selector: 'app-quoate-model',
	templateUrl: './quoate-model.component.html',
	styleUrls: ['./quoate-model.component.css'],
	// encapsulation: ViewEncapsulation.None,
})

export class QuoateModelComponent {
	@Output() closeModal = new EventEmitter<void>();

	isDesktop: boolean | undefined;
	isMobile: boolean = false;

	hideFooter: boolean = false;
	countries: any = [];

    constructor(
		private platform: Platform,
		private formBuilder: FormBuilder,
		private http: HttpClient,
		private alertService: AlertService,
		private router: Router
    ) {
		this.isMobile = this.platform.ANDROID || this.platform.IOS;
		if (!this.isMobile) {
			this.isDesktop = true;
		}
    }
  
    apiurl  = environment.apiUrl;
    blogUrl = environment.blogUrl
  
    quoteForm: any = FormGroup;
    submitted  = false;
    isDisabled = false;
    country_code = '';
    showLoader   = false;
  
    ngOnInit() {
		// document.body.style.position = 'fixed';
		document.body.style.overflow = 'hidden'; 
   		document.body.style.position = 'fixed';  
   		document.body.style.position = 'absolute';  
   		document.body.style.width = '100%'; 
		this.quoteForm = this.formBuilder.group({
			name: ['', Validators.required],
			country_code: ['91'],
			phone_number: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
			message: ['', Validators.required],
			type: ['QUOTE'],
			is_whatsapp: ['']
		});
	
		this.http.get(this.blogUrl + 'countries').subscribe(async response => {
			let data: any = response;
			this.countries = data.response;
		});
	
		this.http.get('https://ipapi.co/json').subscribe(async data => {
			let ClientsData: any = data;
			var countryCode = ClientsData.country_calling_code.replace('+', '');
			this.quoteForm.controls.country_code.setValue(countryCode);
		});
	
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.hideFooter = event.url === '/thankyou'; // Adjust path as needed
			}
		});
    }
  
    get f() { return this.quoteForm.controls; }
  
    onSubmit(event: any) {
		this.submitted = true;
		if (this.quoteForm.invalid) {
			return;
		}
		this.showLoader = true;
		const formData = new FormData(event.target);
		this.http.post(this.blogUrl + 'save_callback_enquiry', formData).subscribe(data => {
			this.isDisabled = false;
			this.showLoader = false;
			let ClientsData: any = data;
			if (ClientsData.success == true) {
				this.alertService.clear();
				this.alertService.success(ClientsData.message);
				this.submitted = false;
				this.quoteForm.reset();
				this.close();
				this.router.navigate(['/thankyou']);
			} else {
				this.alertService.clear();
				this.alertService.error(ClientsData.message);
			}
		});
    }
  
    countryChange(country: any) {
      	this.quoteForm.controls.country_code.setValue(country.dialCode);
    }
  
    numberOnly(event: any): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
    }
  
    close() {
      	this.closeModal.emit();
    }

    closed() {
      	this.closeModal.emit();
    }
	ngOnDestroy(): void {
		// document.body.style.position = '';
		document.body.style.overflow = '';
        document.body.style.position = ''; 
       document.body.style.width = '';
	//    document.body.style.position = 'absolute';
	  }
}
